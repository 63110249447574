import { graphql } from '@api/gql/gql'

export const getOrder = graphql(`
    query getOrderFromUpdateCommentModal($orderId: ID!) {
        order(order_id: $orderId) {
            id
            comment
        }
    }
`)
