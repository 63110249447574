import { graphql } from '@api/gql/gql'

export const getAlbum = graphql(`
    query getAlbumFromSelectAlbumCover($albumId: ID!) {
        album(id: $albumId) {
            id
            cover_type
            cover_color
            cover_filter
            order_id
            pages {
                id
                sort
                width
                height
                type
                images {
                    id
                    album_page_id
                    external_id
                    image_small_url
                    image_medium_url
                    image_large_url
                    width
                    height
                    position_x
                    position_y
                    sort
                    updated_at
                    created_at
                    deleted_at
                }
            }
            updated_at
        }
    }
`)
