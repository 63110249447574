import { ModalType } from './_types'
import { AdminOrderModal } from './admin-order-modal'
import { AlbumPageModal } from './album-page-modal'
import { AlbumPhotosModal } from './album-photos-modal'
import { AlertModal } from './alert-modal'
import { ConfirmModal } from './confirm-modal'
import { EditAlbumMetadataModal } from './edit-album-metadata-modal'
import { LoginModal } from './login-modal'
import { MoveImageModal } from './move-image-modal'
import { SelectAlbumCoverModal } from './select-album-cover-modal'
import { UpdateAlbumLayoutModal } from './update-album-layout-modal'
import { UpdateCommentModal } from './update-comment-modal'

export const loadComponents = () => ({
  [ModalType.ADMIN_ORDER_MODAL]: AdminOrderModal,
  [ModalType.SELECT_ALBUM_COVER_MODAL]: SelectAlbumCoverModal,
  [ModalType.EDIT_ALBUM_METADATA_MODAL]: EditAlbumMetadataModal,
  [ModalType.MOVE_IMAGE_MODAL]: MoveImageModal,
  [ModalType.UPDATE_COMMENT_MODAL]: UpdateCommentModal,
  [ModalType.ALBUM_PAGE_MODAL]: AlbumPageModal,
  [ModalType.ALBUM_PHOTOS_MODAL]: AlbumPhotosModal,
  [ModalType.UPDATE_ALBUM_LAYOUT_MODAL]: UpdateAlbumLayoutModal,
  [ModalType.LOGIN_MODAL]: LoginModal,
  [ModalType.ALERT_MODAL]: AlertModal,
  [ModalType.CONFIRM_MODAL]: ConfirmModal,
})
