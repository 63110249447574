import {
  FC, memo, useCallback, useContext, useMemo,
} from 'react'
import { CoverType } from '@api/gql/graphql'
import { useQuery } from '@apollo/client'
import { faImage, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { WindowSizesContext } from '@share/album'

import { getAlbum } from '../api'

type Props = {
  albumId: string;
  editable?: boolean;
};

export const AlbumCoverHead: FC<Props> = memo((props: Props) => {
  const isMobile = useIsMobile()
  const { openModal } = useModal()
  const windowSizes = useContext(WindowSizesContext)
  const albumState = useQuery(getAlbum, {
    variables: { id: props.albumId },
  })

  const onOpenEditAlbumMetadata = useCallback(() => {
    openModal({
      type: ModalType.EDIT_ALBUM_METADATA_MODAL,
      album_id: props.albumId,
    })
  }, [])

  const onOpenSelectAlbumCover = useCallback(() => {
    openModal({
      type: ModalType.SELECT_ALBUM_COVER_MODAL,
      album_id: props.albumId,
    })
  }, [])

  const headStyle = useMemo(
    () => ({ width: `${isMobile ? windowSizes.outer.width : windowSizes.outerHalf.width}px` }),
    [isMobile, windowSizes.outer, windowSizes.outerHalf],
  )

  const isChoosePhotosDisabled = !props.editable
      || albumState.data?.album?.cover_type === CoverType.Cover_0x0

  return (
    <div style={headStyle}>
      <div className="text-2xl md:text-3xl font-medium text-left mb-2 md:mb-6">
        Cover
      </div>

      <div className="grid grid-cols-2 gap-2">
        <Button
          variant="flat"
          isDisabled={isChoosePhotosDisabled}
          className="text-black bg-gray-very-light"
          startContent={<FontAwesomeIcon icon={faImage} className="text-gray-400" />}
          size={isMobile ? 'sm' : 'lg'}
          onClick={onOpenSelectAlbumCover}
        >
          Choose photos
        </Button>

        <Button
          variant="flat"
          isDisabled={!props.editable}
          className="text-black bg-gray-very-light"
          startContent={<FontAwesomeIcon icon={faPencil} className="text-gray-400" />}
          size={isMobile ? 'sm' : 'lg'}
          onClick={onOpenEditAlbumMetadata}
        >
          Edit name
        </Button>
      </div>
    </div>
  )
})
