import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { HintContext } from '@helpers/hints'
import { OrderEvent, OrderEventsContext } from '@helpers/order-events'

import { currentHintStore } from './current-hint-store'
import { defaultHintType, hints, HintType } from './hint'

const DELAY_SECONDS = 15

type Props = {
  orderId: string
}

// Отображение подсказок
export const useHints = (props: Props) => {
  const orderEvents = useContext(OrderEventsContext)
  const hint = useContext(HintContext)
  const timer = useRef<NodeJS.Timeout>()

  const [currentHintType, setCurrentHintType] = useState<HintType>(
    currentHintStore.read(props.orderId)?.hintType ?? defaultHintType,
  )

  const currentHint = useMemo(
    () => hints.find((h) => h.type === currentHintType),
    [currentHintType],
  )

  const isCloseable = useMemo(
    () => currentHintStore.read(props.orderId)?.skipped ?? false,
    [currentHintType],
  )

  const isClosed = useMemo(
    () => currentHintStore.read(props.orderId)?.closed ?? false,
    [currentHintType],
  )

  // Пропуск текущей подсказки
  const onSkipHint = (isManual?: boolean) => {
    if (isManual) {
      amplitude.event({
        type: AmplitudeEvent.HintsClickSkip,
        hint_type: currentHintType,
        order_id: props.orderId,
      })
    }

    const readedCurrentHint = currentHintStore.read(props.orderId)

    const currentIndex = hints.findIndex((h) => h.type === currentHintType)

    if (currentIndex >= hints.length - 1) {
      // Конец списка означает что все подсказки пропущены
      const selectedHintType = hints[0].type

      const prevClosed = readedCurrentHint?.closed ?? undefined
      currentHintStore.write(props.orderId, {
        hintType: selectedHintType,
        skipped: true,
        // Закрываем подсказки на втором кругу
        closed: readedCurrentHint?.skipped ? true : prevClosed,
      })

      setCurrentHintType(selectedHintType)
    } else {
      // Показываем следующую подсказку
      const selectedHintType = hints[currentIndex + 1].type

      currentHintStore.write(props.orderId, {
        hintType: selectedHintType,
        // Сохраняем состояние подсказки заказа
        skipped: readedCurrentHint?.skipped ?? undefined,
        closed: readedCurrentHint?.closed ?? undefined,
      })

      setCurrentHintType(selectedHintType)
    }
  }

  // Закрытие всех подсказок
  const onCloseHints = (isManual?: boolean) => {
    if (isManual) {
      amplitude.event({
        type: AmplitudeEvent.HintsClickCloseAll,
        order_id: props.orderId,
      })
    }

    currentHintStore.write(props.orderId, {
      hintType: currentHintType,
      skipped: true,
      closed: true,
    })
    onSkipHint()
  }

  // Пропуск текущей подсказки по таймеру
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      onSkipHint()
    }, DELAY_SECONDS * 1000)

    return () => {
      clearTimeout(timer.current)
    }
  }, [currentHintType])

  // Если выполнены целевые действия текущей подсказки, то сразу пропускаем его
  useEffect(() => {
    const events = orderEvents.state.get(props.orderId)
    if (events?.includes(OrderEvent.STAYED) || events?.includes(OrderEvent.SCROLLED_END)) {
      onCloseHints(true)
      return
    }

    events?.forEach((event) => {
      if (currentHint?.orderEvents.includes(event)) {
        onSkipHint()
      }
    })
  }, [orderEvents.state])

  // Сброс состояния
  useEffect(() => {
    if (!hint.showTimestamp) {
      return
    }

    amplitude.event({
      type: AmplitudeEvent.HintsClickOpenAll,
      order_id: props.orderId,
    })

    orderEvents.clearEvents()

    currentHintStore.write(props.orderId, {
      hintType: HintType.READY,
      skipped: undefined,
      closed: undefined,
    })

    setCurrentHintType(HintType.READY)
  }, [hint.showTimestamp])

  return {
    currentHint,
    isCloseable,
    isClosed,
    onSkipHint,
    onCloseHints,
  }
}
