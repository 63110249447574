import { FC, useCallback } from 'react'
import { Order } from '@api/gql/graphql'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { useHints } from '@helpers/hints'
import { useModal } from '@modals/core/use-modal'

import { useOrderCost, usePurchase } from '../utils'

import { CheckoutButton } from './checkout-button'
import { Hint } from './hint'

type Props = {
  order: Order
  currentAlbumId: string
  pagesCount: number
};

export const CheckoutPanel: FC<Props> = (props) => {
  const { openModal } = useModal()

  const { total, subtotal } = useOrderCost({
    orderId: props.order.id,
    pagesCount: props.pagesCount,
  })

  const { onPurchase, isLoading } = usePurchase({ orderId: props.order.id, total })

  const {
    currentHint,
    isCloseable,
    isClosed,
    onSkipHint,
    onCloseHints,
  } = useHints({ orderId: props.order.id })

  const onHintAction = useCallback(() => {
    if (!currentHint?.modalType) {
      return
    }

    amplitude.event({
      type: AmplitudeEvent.HintClick,
      hint_type: currentHint.type,
      order_id: props.order.id,
    })

    openModal({
      type: currentHint.modalType,
      album_id: props.currentAlbumId,
    })
  }, [currentHint])

  return (
    <>
      {isClosed ? (
        <CheckoutButton
          total={total}
          subtotal={subtotal}
          isLoading={isLoading}
          onClick={onPurchase}
        />
      ) : (
        <Hint
          text={currentHint?.message ?? ''}
          isSkip={!isCloseable}
          isClose={isCloseable}
          onSkip={() => onSkipHint(true)}
          onClose={onCloseHints}
          onClick={onHintAction}
        />
      )}
    </>
  )
}
