import { Album, AlbumPageType } from '@api/gql/graphql'
import { useApolloClient } from '@apollo/client'
import { MAX_PHOTOS_PER_PAGE } from '@constants/album'
import { getLiveImages, getLivePages } from '@share/album'

import { getAlbum } from '../api'

// Валидация альбома
export const useAlbumValidation = (albumId: string) => {
  const apolloClient = useApolloClient()

  const getAlbumState = () => {
    const albumCache = apolloClient.readQuery({
      query: getAlbum,
      variables: { id: albumId },
    })

    const album = albumCache?.album as Album

    const minContentPages = albumCache?.album_restrictions.min_content_pages ?? 0
    const maxContentPages = albumCache?.album_restrictions.max_content_pages ?? 0

    const liveAlbumPages = getLivePages(albumCache?.album?.pages ?? [])

    const liveContentAlbumPages = liveAlbumPages.filter(
      (albumPage) => [
        AlbumPageType.Text,
        AlbumPageType.Picture,
      ].includes(albumPage.type as AlbumPageType),
    )

    const albumPageCover = liveAlbumPages.find(
      (albumPage) => albumPage.type === AlbumPageType.FrontCover,
    )

    return {
      album,
      albumPageCover,
      liveAlbumPages,
      liveContentAlbumPages,
      liveAlbumImages: getLiveImages(liveAlbumPages),
      minContentPages,
      maxContentPages,
    }
  }

  // Валидация удаления
  const validateDelete = (deletedAlbumImageIds: string[]): Error | null => {
    const albumState = getAlbumState()

    const newAlbumPages = albumState.liveContentAlbumPages.map((albumPage) => ({
      ...albumPage,
      images: albumPage.images.filter(
        (albumImage) => !deletedAlbumImageIds.includes(albumImage.id),
      ),
    }))

    const changedAlbumPages = getLivePages(newAlbumPages)
    if (changedAlbumPages.length < albumState.minContentPages) {
      return new Error(`There should be a minimum of ${albumState.minContentPages} pages`)
    }

    return null
  }

  // Валидация перемещения
  const validateMove = (
    targetAlbumPageId: string,
    movableAlbumImageIds: string[],
  ): Error | null => {
    const albumState = getAlbumState()

    const targetAlbumPage = albumState.liveContentAlbumPages.find(
      (page) => page.id === targetAlbumPageId,
    )
    if (!targetAlbumPage) {
      return new Error('Target page not found')
    }

    // Проверяем чтобы на целевой странице не было много фотографий
    if (targetAlbumPage.images.length + movableAlbumImageIds.length > MAX_PHOTOS_PER_PAGE) {
      return new Error(`Unable to move. A page can contain a maximum of ${MAX_PHOTOS_PER_PAGE} photos.`)
    }

    // Убираем из страниц выбранные фотографии
    const newAlbumPages = albumState.liveContentAlbumPages.map((albumPage) => ({
      ...albumPage,
      images: albumPage.images.filter(
        (albumImage) => !movableAlbumImageIds.includes(albumImage.id),
      ),
    }))

    // и проверяем ограничение по количеству страниц в альбоме
    const changedAlbumPages = getLivePages(newAlbumPages)
    if (changedAlbumPages.length < albumState.minContentPages) {
      return new Error(`There should be a minimum of ${albumState.minContentPages} pages`)
    }

    return null
  }

  return {
    validateMove,
    validateDelete,
  }
}
