import {
  FC, useEffect, useState,
} from 'react'
import { AlbumInput } from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import { ModalName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { Button } from '@nextui-org/button'
import { Input } from '@nextui-org/input'
import {
  Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,
} from '@nextui-org/modal'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { EditAlbumMetadataModalProps, ModalProps } from '../_types'

import { getAlbum, updateAlbum } from './api'

export const EditAlbumMetadataModal: FC<ModalProps & EditAlbumMetadataModalProps> = (props) => {
  const isMobile = useIsMobile()

  const [input, setInput] = useState<AlbumInput>({
    title: '',
    subtitle: '',
    creator_name: '',
  })

  const [updateAlbumMutation, updateAlbumState] = useMutation(updateAlbum, {
    onCompleted: () => {
      props.onClose()
    },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  useQuery(getAlbum, {
    fetchPolicy: 'cache-only',
    variables: { albumId: props.album_id },
    onCompleted: (data) => {
      if (data.album?.id) {
        setInput({
          id: data.album?.id,
          title: data.album.title,
          subtitle: data.album.subtitle,
          creator_name: data.album.creator_name,
        })
      }
    },
  })

  const onSave = () => {
    if (!props.album_id) {
      return
    }

    amplitude.event({
      type: AmplitudeEvent.AlbumUpdateCover,
      album_id: props.album_id,
    })

    updateAlbumMutation({
      variables: {
        input,
      },
    })
  }

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.AllBookPhotos,
    })
  }, [])

  return (
    <Modal
      placement={isMobile ? 'top-center' : 'top'}
      size={isMobile ? 'full' : 'lg'}
      backdrop="blur"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalContent>
        <>
          <ModalHeader className="flex flex-col pb-0 gap-1 text-center font-medium text-xl lg:text-2xl pt-10">
            Edit the book details
          </ModalHeader>
          <ModalBody className="p-10">
            <Input
              value={input.title ?? ''}
              size={isMobile ? 'md' : 'lg'}
              maxLength={20}
              className="text-base"
              onValueChange={(value) => setInput((s) => ({ ...s, title: value }))}
              label="Book title"
            />

            <Input
              value={input.subtitle ?? ''}
              size={isMobile ? 'md' : 'lg'}
              maxLength={15}
              className="text-base"
              onValueChange={(value) => setInput((s) => ({ ...s, subtitle: value }))}
              label="Book subtitle"
            />

            <Input
              value={input.creator_name ?? ''}
              size={isMobile ? 'md' : 'lg'}
              maxLength={80}
              className="text-base"
              onValueChange={(value) => setInput((s) => ({ ...s, creator_name: value }))}
              label="Book’s author"
            />
          </ModalBody>
          <ModalFooter className="flex justify-center pb-10">
            <Button
              color="primary"
              className="md:w-[360px]"
              size={isMobile ? 'md' : 'lg'}
              onClick={onSave}
              isLoading={updateAlbumState.loading}
            >
              Save
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  )
}

export default EditAlbumMetadataModal
