import { FC, useMemo, useState } from 'react'
import { AlbumImage, AlbumPageType } from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { Container } from '@components/container'
import { toast } from '@components/toast'
import { faEye, faFilePdf, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'
import { Link } from '@nextui-org/link'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@nextui-org/modal'
import { AlbumsNavigation, AlbumView } from '@share/album'
import { uniq } from 'lodash'

import { AdminOrderModalProps, ModalProps } from '../_types'

import { generateAlbum, generateAlbumSpine, getOrder } from './api'

export const AdminOrderModal: FC<AdminOrderModalProps & ModalProps> = (props) => {
  const [currentAlbumId, setCurrentAlbumId] = useState<string | null>(null)

  const orderState = useQuery(getOrder, {
    variables: { orderId: props.orderId },
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const albums = res.order?.albums ?? []
      if (albums.length) {
        setCurrentAlbumId(albums[0].id)
      }
    },
  })

  const order = orderState.data?.order ?? null
  const albums = order?.albums ?? []
  const currentAlbum = useMemo(
    () => albums.find((album) => album.id === currentAlbumId),
    [albums, currentAlbumId],
  )

  const currentAlbumPagesCount = useMemo(
    () => currentAlbum?.pages_count || 0,
    [currentAlbum],
  )

  const contentPages = useMemo(() => currentAlbum?.pages
    ?.filter((page) => [
      AlbumPageType.Picture,
      AlbumPageType.Text,
    ].includes(page.type as AlbumPageType)) ?? [], [currentAlbum])

  const contentImages = useMemo(
    () => contentPages.reduce(
      (images, page) => [...images, ...(page.images as AlbumImage[])],
      [] as AlbumImage[],
    ),
    [contentPages],
  )

  const duplicateCount = useMemo(
    () => {
      const duplicateImages = contentImages.filter((image) => !!image.duplicate_group)
      const groups = uniq(duplicateImages.map((image) => image.duplicate_group))

      return duplicateImages.length - groups.length
    },
    [contentImages],
  )

  const deletedCount = useMemo(
    () => contentImages.filter((albumImage) => !!albumImage.deleted_at).length ?? 0,
    [contentImages],
  )

  const [generateAlbumMutation, generateAlbumState] = useMutation(generateAlbum, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
    onCompleted: () => {
      toast.success('The book generation has been queued')
    },
  })

  const [generateAlbumSpineMutation, generateAlbumSpineState] = useMutation(generateAlbumSpine, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
    onCompleted: () => {
      toast.success('The spine generation has been queued')
    },
  })

  const onGenerateBook = () => {
    if (!currentAlbumId) {
      return
    }

    generateAlbumMutation({ variables: { albumId: currentAlbumId } })
  }

  const onGenerateSpine = () => {
    if (!currentAlbumId) {
      return
    }

    generateAlbumSpineMutation({ variables: { albumId: currentAlbumId } })
  }

  return (
    <Modal
      placement="top"
      size="5xl"
      backdrop="blur"
      scrollBehavior="inside"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              <div>
                Edit order #
                {props.orderId}
              </div>

              {!!currentAlbumId && albums.length > 1 && (
                <div className="mb-3">
                  <AlbumsNavigation
                    albums={albums}
                    currentAlbumId={currentAlbumId}
                    onChangeCurrentAlbumId={setCurrentAlbumId}
                  />
                </div>
              )}
            </ModalHeader>
            <ModalBody className="bg-white">
              <Container>
                {!!currentAlbumId && (
                  <AlbumView
                    albumId={currentAlbumId}
                    key={currentAlbumId}
                    editable
                  />
                )}

                <div className="fixed left-4 top-32 z-50 flex flex-col gap-y-2 mt-2">
                  <div>{`Album id: ${currentAlbumId}`}</div>
                  <div>
                    {`Number of pages after generation: ${currentAlbumPagesCount}`}
                  </div>
                  <div>{`Number of duplicate photos: ${duplicateCount}`}</div>
                  <div>{`Number of deleted photos: ${deletedCount}`}</div>
                  <div>{`Cover edited: ${currentAlbum?.cover_edited ? 'yes' : 'no'}`}</div>

                  <Button
                    as={Link}
                    size="sm"
                    variant="flat"
                    href={`/print/book/${currentAlbumId}`}
                    isDisabled={!currentAlbumId}
                    target="_blank"
                    startContent={<FontAwesomeIcon icon={faEye} />}
                  >
                    Book preview
                  </Button>

                  <Button
                    as={Link}
                    size="sm"
                    variant="flat"
                    href={`/print/spine/${currentAlbumId}`}
                    isDisabled={!currentAlbumId}
                    target="_blank"
                    startContent={<FontAwesomeIcon icon={faEye} />}
                  >
                    Spine preview
                  </Button>

                  <Button
                    size="sm"
                    variant="flat"
                    startContent={<FontAwesomeIcon icon={faFilePdf} />}
                    isDisabled={!currentAlbumId}
                    isLoading={generateAlbumSpineState.loading}
                    onClick={onGenerateSpine}
                  >
                    Generate spine
                  </Button>

                  <Button
                    size="sm"
                    variant="flat"
                    startContent={<FontAwesomeIcon icon={faFilePdf} />}
                    isDisabled={!currentAlbumId}
                    isLoading={generateAlbumState.loading}
                    onClick={onGenerateBook}
                  >
                    Generate book
                  </Button>

                  <Button
                    variant="flat"
                    className="mt-10"
                    startContent={<FontAwesomeIcon icon={faSignOut} />}
                    onClick={onClose}
                  >
                    Close window
                  </Button>
                </div>
              </Container>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default AdminOrderModal
