import { FC, ReactNode } from 'react'
import { Role } from '@api/gql/graphql'
import { getProfile } from '@api/schemas/get-profie'
import { useQuery } from '@apollo/client'
import { NotFoundLayout } from '@layouts/not-found-layout'
import { NotFoundPage } from '@pages/not-found-page'

type Props = {
  roles: Role[]
  children: ReactNode
}

export const ProtectedRoute: FC<Props> = (props) => {
  const profileState = useQuery(getProfile)
  const profile = profileState.data?.profile

  if (!profile) {
    return <>...</>
  }

  if (profile.role && props.roles.includes(profile.role)) {
    return props.children
  }

  return (
    <NotFoundLayout>
      <NotFoundPage />
    </NotFoundLayout>
  )
}
