import { FC } from 'react'
import { Spinner } from '@nextui-org/spinner'
import { clsx } from 'clsx'

type Props = {
  isLoading: boolean
  onClick: () => void
}

export const RecentPhotos: FC<Props> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={clsx(
        'flex w-full h-full justify-center cursor-pointer bg-gray-100 hover:bg-gray-300 transition-colors items-center text-center text-sm rounded-2xl',
      )}
    >
      {props.isLoading ? <Spinner /> : 'Add recent photos'}
    </div>
  )
}
