import {
  FC, memo, useContext, useMemo,
} from 'react'
import { AlbumImage } from '@api/gql/graphql'
import { getProfile } from '@api/schemas'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import {
  faArrowsUpDownLeftRight,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { OrderEvent, OrderEventsContext } from '@helpers/order-events'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Button } from '@nextui-org/button'
import { useAlbumValidation } from '@share/album'

import { deleteAlbumImages, getAlbum } from '../api'
import { PictureControlContext } from '../context'

type Props = {
  albumId: string
  albumPageId: string
  image: AlbumImage;
};

export const AlbumPictureControl: FC<Props> = memo((props: Props) => {
  const pictureControl = useContext(PictureControlContext)
  const orderEvents = useContext(OrderEventsContext)
  const { openModal } = useModal()
  const profileState = useQuery(getProfile, { fetchPolicy: 'cache-only' })
  const apolloClient = useApolloClient()
  const { validateDelete } = useAlbumValidation(props.albumId)

  const [deleteAlbumImagesMutation, deleteAlbumImagesState] = useMutation(deleteAlbumImages, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const onRemove = async () => {
    amplitude.event({
      type: AmplitudeEvent.AlbumDeleteImage,
      image_ids: [props.image.id],
      image_count: 1,
      user_role: profileState.data?.profile.role ?? undefined,
    })

    const albumCache = apolloClient.readQuery({
      query: getAlbum,
      variables: { id: props.albumId },
    })

    orderEvents.registerEvent(
      albumCache?.album?.order_id ?? '',
      OrderEvent.IMAGE_DELETED,
    )

    const validateDeleteError = validateDelete([props.image.id])
    if (validateDeleteError) {
      toast.warning(validateDeleteError.message)
      return
    }

    await deleteAlbumImagesMutation({
      variables: { albumImageIds: [props.image.id] },
      onCompleted: () => {
        toast.success('1 photo have been deleted')
      },
    })
  }

  const onMove = () => {
    amplitude.event({
      type: AmplitudeEvent.AlbumImageClickMove,
      image_id: props.image.id,
      source_page_id: props.albumPageId,
    })

    openModal({
      type: ModalType.MOVE_IMAGE_MODAL,
      album_id: props.albumId,
      album_page_id: props.albumPageId,
      album_image_ids: [props.image.id],
    })
  }

  const iconArrowsUpDownLeftRight = useMemo(
    () => <FontAwesomeIcon icon={faArrowsUpDownLeftRight} className="text-gray-600" />,
    [],
  )

  const iconTrash = useMemo(
    () => <FontAwesomeIcon icon={faTrash} className="text-gray-600" />,
    [],
  )

  return (
    <>
      {pictureControl.move && (
        <Button
          variant="flat"
          size="sm"
          isIconOnly
          className="bottom-12 right-2 absolute bg-white/50 rounded-lg hover:bg-white/70"
          startContent={iconArrowsUpDownLeftRight}
          onClick={onMove}
        />
      )}

      {pictureControl.delete && (
        <Button
          variant="flat"
          size="sm"
          isIconOnly
          isLoading={deleteAlbumImagesState.loading}
          className="bottom-2 right-2 absolute bg-white/50 rounded-lg hover:bg-white/70"
          startContent={iconTrash}
          onClick={onRemove}
        />
      )}
    </>
  )
})
