import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { Spinner } from '@nextui-org/spinner'
import { DateTime } from 'luxon'

export const DeprecatedCreatePage = () => {
  const params = useParams<{ from_unix: string, to_unix: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.DeprecatedCreate,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.DeprecatedCreate,
    })

    facebookPixel.event('PageView')
  }, [])

  useEffect(() => {
    if (params.from_unix && params.to_unix) {
      const fromAt = DateTime.fromSeconds(Number(params.from_unix))
      const toAt = DateTime.fromSeconds(Number(params.to_unix))
      navigate(`/?range=${fromAt.toFormat('yyyy-MM-dd')},${toAt.toFormat('yyyy-MM-dd')}`)
    }
  }, [])

  return (
    <div className="">
      <Spinner />
    </div>
  )
}

export default DeprecatedCreatePage
