import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { Pagination } from '@components/pagination'
import { toast } from '@components/toast'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { useApolloError } from '@helpers/apollo-error'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { usePagination } from '@helpers/use-pagination'
import { useHeaderContext } from '@layouts/context'
import { Spinner } from '@nextui-org/spinner'

import { getOrders } from './api'
import { OrderItem } from './components'

const LIMIT = 20

export const OrdersPage = () => {
  const headerContext = useHeaderContext()

  const { paginationInput, onPage } = usePagination({ limit: LIMIT })

  const getOrdersOwnState = useQuery(getOrders, {
    fetchPolicy: 'network-only',
    variables: { pagination: paginationInput },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  useEffect(() => {
    headerContext.setBackPath(import.meta.env.VITE_LANDING_PAGE_URL)

    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Orders,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Orders,
    })

    facebookPixel.event('PageView')
  }, [])

  const pageError = useApolloError([getOrdersOwnState.error])

  const orders = getOrdersOwnState.data?.orders_own.items ?? []
  const pageInfo = getOrdersOwnState.data?.orders_own?.page_info

  return (
    <div className="page">
      <Alert type={AlertType.error} message={pageError} />

      <div className="mb-2 md:mb-10 text-3xl font-medium text-center">Orders</div>

      <div>
        {getOrdersOwnState.loading
          ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )
          : orders.map((order) => (
            <OrderItem key={order.id} order={order} />
          ))}
      </div>

      {!orders.length && !getOrdersOwnState.loading && (
        <div className="text-gray-500 text-center">You don’t have any orders yet</div>
      )}

      {pageInfo?.total === LIMIT && (
        <Pagination
          pageInfo={pageInfo}
          onPage={onPage}
        />
      )}
    </div>
  )
}

export default OrdersPage
