import { FC, useMemo } from 'react'
import { Album } from '@api/gql/graphql'
import { ASSET_URL } from '@constants/link'

type Props = {
  album: Album
}

export const AlbumItem: FC<Props> = (props) => {
  const quantityLabel = useMemo(
    () => ((props.album.quantity || 0) > 1 ? ` (${props.album.quantity} qty)` : ''),
    [props.album.quantity],
  )

  const name = useMemo(() => {
    return props.album.part ? `Part ${props.album.part}` : 'Single book'
  }, [props.album.part])

  return (
    <div className="flex flex-col gap-y-1 justify-center" key={props.album.id}>
      <div className="grid grid-cols-6 border-b py-1">
        <div className="col-span-2 text-gray-500">
          {name}
          {' '}
          {quantityLabel}
          :
        </div>
        <div className="col-span-4 text-right">
          {props.album.book ? (
            <a
              className="text-primary underline"
              href={`${ASSET_URL}/${props.album.book?.uuid}`}
              target="_blank"
              rel="noreferrer"
            >
              {props.album.book_status}
            </a>
          ) : props.album.book_status}

          {' / '}

          {props.album.spine ? (
            <a
              className="text-primary underline"
              href={`${ASSET_URL}/${props.album.spine.uuid}`}
              target="_blank"
              rel="noreferrer"
            >
              {props.album.spine_status}
            </a>
          ) : props.album.spine_status}
        </div>
      </div>
    </div>
  )
}
