import { AlbumPageType, CoverType } from '@api/gql/graphql'
import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { defaultPageFormat, parseCoverSizes } from '@constants/album'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { ModalType, useModal } from '@modals/index'
import { getAlbum } from '@pages/order-page/api/get-album'

import { getOrder, payOrder } from '../api'

type Props = {
  orderId: string
  total: number
}

export const usePurchase = (props: Props) => {
  const apolloClient = useApolloClient()
  const { openModal } = useModal()

  const [payOrderMutation, payOrderState] = useMutation(payOrder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  // Валидация обложек альбомов заказа
  const validateCovers = () => {
    const orderCache = apolloClient.readQuery({
      query: getOrder,
      variables: { orderId: String(props.orderId) },
    })

    const albums = orderCache?.order?.albums ?? []
    for (const album of albums) {
      const albumCache = apolloClient.readQuery({
        query: getAlbum,
        variables: { id: album.id },
      })

      const albumPageCover = albumCache?.album?.pages?.find(
        (albumPage) => albumPage.type === AlbumPageType.FrontCover,
      )

      const liveCoverImages = albumPageCover?.images?.filter(
        (albumImage) => !albumImage.deleted_at,
      ) ?? []

      // Проверяем чтобы у каждого альбома было достаточно фотографий на обложке
      const coverSizes = parseCoverSizes(albumCache?.album?.cover_type as CoverType)
      if (coverSizes.count > liveCoverImages.length) {
        return {
          error: 'Not enough photos in the cover',
          albumId: album.id,
        }
      }
    }

    return null
  }

  const onPurchase = () => {
    const validateCoversErrors = validateCovers()
    if (validateCoversErrors) {
      openModal({
        type: ModalType.SELECT_ALBUM_COVER_MODAL,
        album_id: validateCoversErrors.albumId,
      })
      toast.warning(validateCoversErrors.error)
      return
    }

    amplitude.event({
      type: AmplitudeEvent.OrderClickPurchase,
      order_id: props.orderId,
    })

    ga.event({
      category: GaCategory.Order,
      action: GaAction.OrderClickPay,
      value: props.total,
    })

    payOrderMutation({
      variables: { orderId: props.orderId },
    }).then((res) => {
      const paymentUrl = res.data?.pay_order.payment_url
      if (!paymentUrl) {
        return
      }

      facebookPixel.event('AddToCart', {
        currency: 'USD',
        value: res.data?.pay_order.id ?? 0,
        contents: [
          {
            id: defaultPageFormat,
            quantity: 1,
          },
        ],
        content_type: 'product_group',
        event_id: res.data?.pay_order.id ?? '',
      })

      window.location.href = paymentUrl
    })
  }

  return {
    onPurchase,
    isLoading: payOrderState.loading,
  }
}
