import { FC, useMemo } from 'react'
import { CoverType } from '@api/gql/graphql'
import { coverTypes, defaultCoverType, parseCoverSizes } from '@constants/album'
import { clsx } from 'clsx'

import { getCoverTypeClass } from '../constants'

import { CoverItem } from './cover-item'

type Props = {
  coverType: CoverType
  isActive?: boolean
  onClick?: () => void
};

export const CoverLayout: FC<Props> = (props) => {
  const coverType = useMemo(() => {
    if (coverTypes.includes(props.coverType)) {
      return props.coverType
    }

    return defaultCoverType
  }, [props.coverType])

  const items = useMemo(() => {
    const { count } = parseCoverSizes(coverType)

    return Array.from({ length: count }).map((_, index) => index)
  }, [coverType])

  return (
    <div
      className={clsx(
        'flex h-full flex-col aspect-square  rounded-md  hover:bg-primary group transition-colors p-1.5 md:p-1.5 cursor-pointer',
        props.isActive ? 'bg-primary' : 'bg-gray-blue-light',
        coverType !== CoverType.Cover_0x0 && 'justify-end',
      )}
      onClick={props.onClick}
    >
      {coverType === CoverType.Cover_0x0 ? (
        <div className="mb-0.5 mt-0.5">
          <div className={clsx(
            'group-hover:bg-white w-20 h-2.5 mb-1',
            props.isActive ? 'bg-white' : 'bg-gray-cold-light',
          )}
          />
          <div className={clsx(
            'bg-gray-cold-light group-hover:bg-white w-10 h-2.5',
            props.isActive ? 'bg-white' : 'bg-gray-cold-light',
          )}
          />
        </div>
      ) : (
        <div
          className={clsx(
            'grid gap-0.5 md:gap-1',
            getCoverTypeClass(coverType),
          )}
        >
          {items.map((item) => (
            <CoverItem
              key={item}
              isActive={props.isActive}
              coverType={coverType}
            />
          ))}
        </div>
      )}
    </div>
  )
}
