import { FC, memo } from 'react'
import { CoverColor } from '@api/gql/graphql'
import { clsx } from 'clsx'

type Props = {
  coverColor: CoverColor;
  isActive?: boolean
  onClick?: () => void
};

export const CoverColorSquare: FC<Props> = memo((props: Props) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center w-10 h-10 border rounded-lg outline outline-2  hover:outline-primary transition-all cursor-pointer',
        props.coverColor === CoverColor.White && 'bg-white',
        props.coverColor === CoverColor.Red && 'bg-cover-red',
        props.coverColor === CoverColor.Beige && 'bg-cover-beige',
        props.coverColor === CoverColor.Green && 'bg-cover-green',
        props.coverColor === CoverColor.Blue && 'bg-cover-blue',
        props.isActive ? 'outline-primary' : 'outline-transparent',
      )}
      onClick={props.onClick}
    />
  )
})
