import { FC, useMemo } from 'react'
import { Task, TaskStatus } from '@api/gql/graphql'
import { dropTask } from '@api/schemas/drop-task'
import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { taskKey } from '@constants/task'
import { faRepeat, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toHumanReadable } from '@helpers/i18n'
import { Button } from '@nextui-org/button'
import { Modal, ModalBody, ModalContent } from '@nextui-org/modal'
import { Spinner } from '@nextui-org/spinner'

type Props = {
  isOpen: boolean;
  task?: Task | null;
};

export const CreateAlbumsStatus: FC<Props> = (props) => {
  const [dropTaskMutation, dropTaskState] = useMutation(dropTask, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const isLoading = useMemo(() => {
    const taskStatus = props.task?.status
    if (!taskStatus) {
      return false
    }

    return [TaskStatus.Created, TaskStatus.Executing].includes(taskStatus)
  }, [props])

  const lastStep = useMemo(() => {
    if (!props.task?.steps?.length) {
      return ''
    }

    return props.task.steps[props.task.steps.length - 1]
  }, [props.task?.steps])

  const isFailed = props.task?.status === TaskStatus.Failed

  const onTryAgain = async () => {
    await dropTaskMutation({
      variables: { key: taskKey.createAlbums() },
    })
    window.location.reload()
  }

  return (
    <Modal
      placement="center"
      size="sm"
      backdrop="blur"
      isOpen={props.isOpen}
      hideCloseButton
    >
      <ModalContent className="py-10">
        {(onClose) => (
          <form onSubmit={onClose}>
            <ModalBody>
              <div className="flex flex-col items-center">
                {isLoading && (
                  <Spinner
                    className="mb-4"
                    size="sm"
                  />
                )}

                {isFailed && (
                  <FontAwesomeIcon
                    className="mb-4 size-5 text-red-500"
                    icon={faWarning}
                  />
                )}

                <div className="text-lg font-bold">{toHumanReadable(props.task?.status || '')}</div>
                <div className="text-gray-600 text-center">{lastStep}</div>

                {isFailed && (
                  <Button
                    variant="flat"
                    className="w-fit mt-4"
                    size="sm"
                    startContent={<FontAwesomeIcon icon={faRepeat} />}
                    isLoading={dropTaskState.loading}
                    onClick={onTryAgain}
                  >
                    Try again
                  </Button>
                )}
              </div>
            </ModalBody>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}
