import { graphql } from '@api/gql/gql'

export const getOrder = graphql(`
    query getOrderFromModal($orderId: ID!) {
        order(order_id: $orderId) {
            id
            status
            created_at
            albums {
                id
                title
                subtitle
                from_at
                to_at
                pages_count
                cover_edited
                
                pages {
                    id
                    type
                    images {
                        id
                        duplicate_group
                        deleted_at
                    }
                }
            }
        }
    }
`)
