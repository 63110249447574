import {
  createContext, FC, ReactNode, useEffect, useMemo, useState,
} from 'react'
import { uniq } from 'lodash'

// События заказа нужны для подсказок.
// Чтобы при наступлении целевого действия был пропуск подсказки
export enum OrderEvent {
  COVER_EDITED = 'cover-edited',
  ALL_BOOK_PHOTOS_VIEWED = 'all-book-photos-viewed',
  IMAGE_DELETED = 'image-deleted',
  IMAGE_MOVED = 'image-moved',
  STAYED = 'stayed',
  SCROLLED_END = 'end',
}

type State = Map<string, OrderEvent[]>

type OrderEventsState = {
  state: State
  registerEvent: (orderId: string, orderEvent: OrderEvent) => void
  clearEvents: () => void
}

export const OrderEventsContext = createContext<OrderEventsState>({
  state: new Map(),
  registerEvent: () => {},
  clearEvents: () => {},
})

const storeKey = 'order-events'

const restoreFromStore = (): State => {
  try {
    const value = localStorage.getItem(storeKey)
    return !value ? new Map() : new Map(JSON.parse(value))
  } catch (e) {
    return new Map()
  }
}

const saveToStore = (state: State) => {
  return localStorage.setItem(storeKey, JSON.stringify(Array.from(state.entries())))
}

export const OrderEventsContextProvider: FC<{ children: ReactNode }> = (props) => {
  const children = useMemo(() => props.children, [props.children])

  const [state, setState] = useState<State>(restoreFromStore())

  const value = useMemo(():OrderEventsState => ({
    state,
    registerEvent: (orderId, orderEvent) => {
      setState((s) => {
        const updatedMap = new Map(s)
        const events = updatedMap.get(orderId) || []
        updatedMap.set(orderId, uniq([...events, orderEvent]))
        return updatedMap
      })
    },
    clearEvents: () => {
      setState(() => new Map())
    },
  }), [state])

  useEffect(() => {
    saveToStore(state)
  }, [state])

  return (
    <OrderEventsContext.Provider value={value}>
      {children}
    </OrderEventsContext.Provider>
  )
}
