import { ReactNode, useMemo } from 'react'
import { Container } from '@components/container'
import { HeaderContextProvider } from '@layouts/context'

import { Header } from './components'

type Props = {
  children: ReactNode
  title?: string
  onBack?: () => void
}

export const ModalLayout = (props: Props) => {
  const initialState = useMemo(
    () => ({ title: props.title }),
    [props.title],
  )

  return (
    <div style={{ minHeight: '100vh' }} className="flex flex-col justify-between">
      <Container className="pt-5">
        <HeaderContextProvider initialState={initialState}>
          <Header onBack={props.onBack} />
        </HeaderContextProvider>
        {props.children}
      </Container>
    </div>
  )
}
