import { graphql } from '@api/gql/gql'

export const moveAlbumImages = graphql(`
    mutation moveAlbumImages($albumId: ID!, $albumImageIds: [ID!]!, $targetAlbumPageId: ID!) {
        move_album_images(album_id: $albumId, album_image_ids: $albumImageIds, target_album_page_id: $targetAlbumPageId) {
            id
            pages {
                id
                sort
                width
                height
                type
                images {
                    id
                    album_page_id
                    external_id
                    image_small_url
                    image_medium_url
                    image_large_url
                    width
                    height
                    position_x
                    position_y
                    sort
                    updated_at
                    created_at
                    deleted_at
                }
            }
        }
    }
`)
