import { graphql } from '@api/gql/gql'

export const getAlbum = graphql(`
    query getAlbumFromEditAlbumCover($albumId: ID!) {
        album(id: $albumId) {
            id
            title
            subtitle
            creator_name
            updated_at
        }
    }
`)
