import { FC, useEffect, useState } from 'react'
import { AlbumInput, CoverType } from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import {
  coverColors,
  coverFilters, coverTypes,
  defaultCoverColor,
  defaultCoverFilter,
  defaultCoverType,
} from '@constants/album'
import { ModalName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { Button } from '@nextui-org/button'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/modal'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { CoverLayout } from '@share/album'

import { ModalProps, UpdateAlbumLayoutModalProps } from '../_types'

import { getAlbum, updateAlbum } from './api'
import { CoverColorSquare, CoverFilterSquare } from './components'

export const UpdateAlbumLayoutModal: FC<ModalProps & UpdateAlbumLayoutModalProps> = (props) => {
  const isMobile = useIsMobile()

  const [input, setInput] = useState<AlbumInput>({
    cover_filter: defaultCoverFilter,
    cover_color: defaultCoverColor,
    cover_type: defaultCoverType,
  })

  const [updateAlbumMutation, updateAlbumState] = useMutation(updateAlbum, {
    onCompleted: () => {
      props.onClose()
    },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  useQuery(getAlbum, {
    fetchPolicy: 'cache-only',
    variables: { albumId: props.album_id },
    onCompleted: (data) => {
      if (data.album?.id) {
        setInput({
          id: data.album?.id,
          cover_filter: data.album.cover_filter,
          cover_color: data.album.cover_color,
          cover_type: data.album.cover_type,
        })
      }
    },
  })

  const onSave = () => {
    if (!props.album_id) {
      return
    }

    amplitude.event({
      type: AmplitudeEvent.AlbumUpdateCoverLayout,
      album_id: props.album_id,
    })

    const isValid = coverTypes.includes(input.cover_type as CoverType)

    updateAlbumMutation({
      variables: {
        input: {
          ...input,
          cover_type: isValid ? input.cover_type : defaultCoverType,
        },
      },
    })
  }

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.UpdateLayout,
    })
  }, [])

  return (
    <Modal
      placement={isMobile ? 'top-center' : 'top'}
      size="xl"
      backdrop="blur"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col pb-0 gap-1 text-center font-medium text-xl lg:text-2xl pt-10">
          Edit the cover
        </ModalHeader>
        <ModalBody className="gap-y-6">
          <div>
            <div className="mb-1 md:mb-3 text-default-400 font-medium">
              Photo filter
            </div>
            <div className="flex gap-x-1 md:gap-x-2">
              {coverFilters.map((coverFilter) => (
                <CoverFilterSquare
                  key={coverFilter}
                  coverFilter={coverFilter}
                  isActive={input.cover_filter === coverFilter}
                  onClick={() => {
                    setInput((s) => ({
                      ...s,
                      cover_filter: coverFilter,
                    }))
                  }}
                />
              ))}
            </div>
          </div>

          <div>
            <div className="mb-1 md:mb-3 text-default-400 font-medium">Cover color</div>
            <div className="flex gap-x-1 md:gap-x-2">
              {coverColors.map((coverColor) => (
                <CoverColorSquare
                  key={coverColor}
                  coverColor={coverColor}
                  isActive={input.cover_color === coverColor}
                  onClick={() => {
                    setInput((s) => ({
                      ...s,
                      cover_color: coverColor,
                    }))
                  }}
                />
              ))}
            </div>
          </div>

          <div className="w-full md:w-fit">
            <div className="mb-1 md:mb-3 text-default-400 font-medium">Layout</div>
            <div className="grid grid-cols-3 gap-1 md:gap-3">
              {coverTypes.map((coverType) => (
                <CoverLayout
                  isActive={input.cover_type === coverType}
                  key={coverType}
                  coverType={coverType}
                  onClick={() => {
                    setInput((s) => ({
                      ...s,
                      cover_type: coverType,
                    }))
                  }}
                />
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex justify-center pb-10">
          <Button
            color="primary"
            className="md:w-[360px] w-full"
            size={isMobile ? 'md' : 'lg'}
            onClick={onSave}
            isLoading={updateAlbumState.loading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateAlbumLayoutModal
