import { graphql } from '@api/gql/gql'

export const subscriptionOnTask = graphql(`
    subscription subscriptionOnTask($key: String!) {
        subscribe_on_task(key: $key) {
            status
            steps
        }
    }
`)
