import { useMemo } from 'react'
import { useParams } from 'react-router'
import { CoverColor, PageFormat } from '@api/gql/graphql'
import { useQuery } from '@apollo/client'
import { parsePageFormat } from '@constants/album'
import { clsx } from 'clsx'

import { getAlbum } from './api'

export const PrintSpinePage = () => {
  const { albumId } = useParams<{ albumId: string }>()

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-and-network',
    variables: { id: albumId as string },
  })

  const album = getAlbumState.data?.album
  const pageFormat = album?.page_format || PageFormat.Format_210x210

  const windowSizes = useMemo(() => parsePageFormat(pageFormat), [pageFormat])

  const textColor = useMemo(() => {
    const coverColor = album?.cover_color as CoverColor

    return {
      title: coverColor === CoverColor.White ? 'text-dark-gray' : 'text-white',
      text: coverColor === CoverColor.White ? 'text-gray-dark' : 'text-white',
    }
  }, [album?.cover_color])

  return (
    <div
      className={clsx(
        'flex justify-between h-full w-full text-[1em] py-[42px] font-album border',
        import.meta.env.DEV && 'border border-orange-500',
        album?.cover_color === CoverColor.White && 'bg-white',
        album?.cover_color === CoverColor.Red && 'bg-cover-red border-cover-red',
        album?.cover_color === CoverColor.Beige && 'bg-cover-beige border-cover-beige',
        album?.cover_color === CoverColor.Green && 'bg-cover-green border-cover-green',
        album?.cover_color === CoverColor.Blue && 'bg-cover-blue border-cover-blue',
      )}
      style={{
        writingMode: 'vertical-rl',
        height: `${windowSizes.height}px`,
      }}
    >
      <div className={clsx(
        'flex justify-center items-center leading-none text-[1.6em] font-regular',
        textColor.title,
      )}
      >
        {album?.title}
      </div>

      {!!album?.part && (
        <div
          className={clsx(
            'flex flex-col justify-center items-start leading-none tracking-normal text-[1.2em]',
            textColor.text,
          )}
        >
          {`Part ${album.part}`}
        </div>
      )}

      <div className={clsx(
        'flex flex-col justify-center items-start leading-none tracking-wide text-[1.2em]',
        textColor.text,
      )}
      >
        {album?.subtitle}
      </div>
    </div>
  )
}

export default PrintSpinePage
