import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getProfile } from '@api/schemas/get-profie'
import {
  useMutation,
  useQuery,
} from '@apollo/client'
import { toast } from '@components/toast'
import { ModalName, PageName } from '@constants/analytic'
import { ERROR } from '@constants/error'
import {
  faArrowRightFromBracket,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  amplitude,
  AmplitudeEvent,
} from '@helpers/amplitude'
import { useApolloErrorCode } from '@helpers/apollo-error'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { tokenStore } from '@helpers/token-store'
import { useHeaderContext } from '@layouts/context'
import { ModalType, useModal } from '@modals/index'
import { Avatar } from '@nextui-org/avatar'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { deleteProfile, logout } from './api'

export const ProfilePage = () => {
  const isMobile = useIsMobile()
  const headerContext = useHeaderContext()
  const { openModal } = useModal()
  const navigate = useNavigate()

  const getProfileState = useQuery(getProfile, {
    fetchPolicy: 'cache-and-network',
  })

  const profile = getProfileState.data?.profile ?? null
  const errorCode = useApolloErrorCode([getProfileState.error])
  const isGuest = profile === null || errorCode === ERROR.UNAUTHORIZED

  const [deleteProfileMutation, deleteProfileState] = useMutation(
    deleteProfile,
    {
      onCompleted: () => {
        tokenStore.remove()
        window.location.href = import.meta.env.VITE_LANDING_PAGE_URL
      },
      onError: (err) => {
        if (err.graphQLErrors.length) {
          toast.error(err.graphQLErrors[0].message)
        }
      },
    },
  )
  const [logoutMutation, logoutState] = useMutation(logout, {
    onCompleted: () => {
      tokenStore.remove()
      window.location.href = '/'
    },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const onLogout = () => {
    amplitude.event({ type: AmplitudeEvent.LogoutClick })

    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'Log out of your account?',
      message: '',
      onCancel: () => {},
      onConfirm: () => {
        logoutMutation().then(() => {
          navigate('/')
        })
      },
    })
  }

  const onDeleteAccount = () => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.AccountDelete,
    })

    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'Delete your account?',
      message:
        'You are about to delete your profile. You’ll lose all your orders and wont be able to undo this.',
      onConfirm: () => {
        amplitude.event({ type: AmplitudeEvent.ProfileConfirmAccountDelete })

        deleteProfileMutation()
      },
      onCancel: () => {},
    })
  }

  useEffect(() => {
    headerContext.setBackPath(import.meta.env.VITE_LANDING_PAGE_URL)

    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Profile,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Profile,
    })

    facebookPixel.event('PageView')
  }, [])

  return (
    <div className="flex flex-col items-center justify-center">
      <Avatar
        size="lg"
        src={isGuest ? undefined : profile?.avatar_url ?? undefined}
        className="bg-gray-100 text-gray-300 mb-4"
      />
      <div className="text-black text-lg lg:text-2xl font-bold">
        {isGuest ? 'You’re not logged in' : profile?.name}
      </div>
      <div className="text-sm lg:text-base text-gray-500 mb-3">{profile?.email}</div>

      {!isGuest && (
        <div className="flex gap-2">
          <Button
            variant="bordered"
            startContent={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
            isLoading={logoutState.loading}
            onClick={onLogout}
            size={isMobile ? 'sm' : 'md'}
          >
            Logout
          </Button>
          <Button
            variant="bordered"
            color="danger"
            startContent={
              <FontAwesomeIcon className="text-red-600" icon={faTrash} />
              }
            isLoading={deleteProfileState.loading}
            onClick={onDeleteAccount}
            size={isMobile ? 'sm' : 'md'}
          >
            Delete account
          </Button>
        </div>
      )}
    </div>
  )
}

export default ProfilePage
