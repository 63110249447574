import { graphql } from '@api/gql/gql'

export const getAlbum = graphql(`
    query getAlbumFromOrderPage($id: ID!) {
        album(id: $id) {
            id
            cover_type
            pages {
                id
                type
                images {
                    id
                    deleted_at
                }
            }
        }
    }
`)
