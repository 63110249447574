import { FC, memo } from 'react'
import { AlbumImage } from '@api/gql/graphql'
import { AlbumPagePagination } from '@share/album/types'
import { clsx } from 'clsx'

type Props = {
  id?: string
  albumImage: AlbumImage & AlbumPagePagination
  isActive: boolean
  isPageNumber?: boolean
  isShowDuplicate?: boolean
  onClick: () => void
}

export const AlbumImageItem: FC<Props> = memo((props: Props) => {
  return (
    <div
      id={props.id}
      className={clsx(
        'w-full aspect-square relative flex justify-center cursor-pointer rounded-2xl',
      )}
      onClick={props.onClick}
    >
      <img
        className={clsx(
          'object-cover w-full h-full rounded-2xl border-2  md:hover:border-primary transition-colors',
          props.isActive ? 'border-primary' : 'border-white',
        )}
        src={props.albumImage.image_small_url ?? ''}
        alt=""
      />

      {!!props.albumImage.duplicate_group && props.isShowDuplicate && (
        <div
          className={clsx(
            'absolute top-2 right-2 z-10 rounded-full flex items-center justify-center px-1 md:py-1 md:px-1.5 text-micro md:text-xs',
            'bg-blue-500 text-white',
          )}
        >
          Duplicate
        </div>
      )}

      {props.isActive && (
        <div className="absolute w-full h-full bg-primary/20 rounded-2xl" />
      )}

      {props.isPageNumber && (
        <div
          className={clsx(
            'absolute bottom-2 z-10 rounded-full flex items-center justify-center px-1 md:py-1 md:px-1.5 text-micro md:text-xs',
            props.isActive
              ? 'bg-primary text-white'
              : 'bg-gray-light text-black',
          )}
        >
          {`Page ${props.albumImage.page_number}`}
        </div>
      )}
    </div>
  )
})
