import { FC } from 'react'
import { CoverType } from '@api/gql/graphql'
import { clsx } from 'clsx'

import { getCoverItemClass } from '../constants'

type Props = {
  coverType: CoverType
  isActive?: boolean
}

export const CoverItem: FC<Props> = (props) => {
  return (
    <div className={clsx(
      ' group-hover:bg-white rounded-sm',
      getCoverItemClass(props.coverType),
      props.isActive ? 'bg-white' : 'bg-gray-cold-light',
    )}
    />
  )
}
