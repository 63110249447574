import { Album } from '@api/gql/graphql'
import { useMutation } from '@apollo/client'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/index'
import { DateTime } from 'luxon'

import { deleteOrder } from '../api'

export const useRecentPhotos = () => {
  const { openModal } = useModal()
  const [deleteOrderMutation, deleteOrderState] = useMutation(deleteOrder)

  const addRecentPhotos = (album: Pick<Album, 'id' | 'from_at' | 'to_at' | 'order_id'>) => {
    amplitude.event({
      type: AmplitudeEvent.AlbumClickAddRecentPhotos,
      album_id: album.id,
    })

    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'Add recent photos',
      message: '⚠️ To add recent photos, we need to create a new photo book. This means any adjustments made will be lost. Click ‘Continue’ to proceed or ‘Cancel’ to keep your current photo book edits.',
      confirmTitle: 'Continue',
      onConfirm: () => {
        deleteOrderMutation({
          variables: { orderId: album.order_id ?? '' },
          onCompleted: () => {
            const fromAt = DateTime.fromISO(album.from_at)
            const toAt = DateTime.fromISO(album.to_at)

            window.location.href = `/?range=${fromAt.toFormat('yyyy-MM-dd')},${toAt.toFormat('yyyy-MM-dd')}`
          },
        })
      },
      onCancel: () => {},
    })
  }

  return { addRecentPhotos, isLoadingRecentPhotos: deleteOrderState.loading }
}
