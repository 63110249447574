import { FC, memo } from 'react'
import { AlbumPhotosTab } from '@modals/album-photos-modal/constants'
import { Tab, Tabs } from '@nextui-org/tabs'
import { Key } from '@react-types/shared'

type Props = {
  currentTab: AlbumPhotosTab
  onChangeTab: (key: Key) => void
}

export const NavigationTabs: FC<Props> = memo((props: Props) => {
  return (
    <div className="flex justify-center mb-2">
      <Tabs selectedKey={props.currentTab} onSelectionChange={props.onChangeTab}>
        <Tab key={AlbumPhotosTab.IN_USE} title="In use" />
        <Tab key={AlbumPhotosTab.DELETED} title="Deleted" />
      </Tabs>
    </div>
  )
})
