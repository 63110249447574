import {
  FC, useCallback, useContext, useEffect, useMemo, useRef,
} from 'react'
import { AlbumPageType } from '@api/gql/graphql'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import { ModalName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { OrderEvent, OrderEventsContext } from '@helpers/order-events'
import {
  Modal, ModalContent, ModalHeader,
} from '@nextui-org/modal'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import {
  PageItem,
  useAlbumValidation,
  usePages, useWindowSizes, WindowSizesContext,
} from '@share/album'

import { ModalProps, MoveImageModalProps } from '../_types'

import { getAlbum, moveAlbumImages } from './api'

export const MoveImageModal: FC<ModalProps & MoveImageModalProps> = (props) => {
  const orderEvents = useContext(OrderEventsContext)
  const isMobile = useIsMobile()
  const apolloClient = useApolloClient()
  const { validateMove } = useAlbumValidation(props.album_id)
  const bodyRef = useRef<HTMLDivElement | null>(null)

  const getPageTag = (pageId: string) => `modal_page_${pageId}`

  // Скролл к выбранной странице
  const scrollToPage = () => {
    if (!bodyRef.current || !props.album_page_id) {
      return
    }

    const albumPageElement = document.getElementById(getPageTag(props.album_page_id))
    if (!albumPageElement) {
      return
    }

    bodyRef.current.scrollTo({
      top: albumPageElement.offsetTop - bodyRef.current.offsetTop,
      behavior: 'smooth',
    })
  }

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-and-network',
    variables: { albumId: props.album_id },
    onCompleted: () => {
      scrollToPage()
    },
  })

  const album = useMemo(() => getAlbumState.data?.album, [getAlbumState.data?.album])
  const albumPages = album?.pages ?? []

  const windowSizes = useWindowSizes({ defaultSize: isMobile ? 2 : 4 })
  const { pagesLive } = usePages(albumPages)

  const pages = useMemo(() => pagesLive.filter(
    (page) => [AlbumPageType.Picture, AlbumPageType.Text].includes(page.type as AlbumPageType),
  ), [pagesLive])

  const [moveAlbumImagesMutation] = useMutation(moveAlbumImages, {
    onCompleted: () => {
      if (props.onCompleted) {
        props.onCompleted()
      }

      toast.success(`${props.album_image_ids.length} photo(s) have been moved`)
    },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const onMove = useCallback((targetAlbumPageId: string) => {
    if (!album) {
      return
    }

    amplitude.event({
      type: AmplitudeEvent.AlbumImageSelectPage,
      image_ids: props.album_image_ids,
      target_page_id: targetAlbumPageId,
    })

    const albumCache = apolloClient.readQuery({
      query: getAlbum,
      variables: { albumId: album.id },
    })

    orderEvents.registerEvent(
      albumCache?.album?.order_id ?? '',
      OrderEvent.IMAGE_MOVED,
    )

    const validateMoveError = validateMove(
      targetAlbumPageId,
      props.album_image_ids,
    )
    if (validateMoveError) {
      toast.warning(validateMoveError.message)
      return
    }

    moveAlbumImagesMutation({
      variables: {
        albumId: album?.id,
        albumImageIds: props.album_image_ids,
        targetAlbumPageId,
      },
    })

    props.onClose()
  }, [moveAlbumImagesMutation])

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.MoveImage,
    })
  }, [])

  const onMoveHandlers = useMemo(() => {
    const handlers: Record<string, () => void> = {}
    pages.forEach((page) => {
      handlers[page.id] = () => onMove(page.id)
    })
    return handlers
  }, [pages, onMove])

  return (
    <Modal
      placement={isMobile ? 'bottom-center' : 'top'}
      size={isMobile ? 'full' : '5xl'}
      backdrop="blur"
      scrollBehavior="inside"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1 font-medium text-center text-xl lg:text-2xl pt-10">
          Choose the page to move the photo
        </ModalHeader>

        <div
          ref={bodyRef}
          className="flex flex-1 flex-col px-3 md:px-6 py-2 overflow-y-auto gap-0"
        >
          <WindowSizesContext.Provider value={windowSizes}>
            {album && (
            <div ref={windowSizes.ref} className="grid grid-cols-2 md:grid-cols-4 md:gap-4 relative">
              {pages.map((page, index) => (
                <div key={page.id}>
                  <div className="font-medium text-base mb-2">
                    {`Page ${page.page_number}`}
                  </div>

                  <PageItem
                    id={getPageTag(page.id)}
                    key={page.id}
                    album={album}
                    page={page}
                    spreadIndex={0}
                    isDivider={isMobile && index % 2 === 0 && pages.length - 1 !== index}
                    isLeft
                    selectedImageIds={props.album_image_ids}
                    onClick={onMoveHandlers[page.id]}
                    pageLayoutClassName="outline outline-2 outline-transparent hover:outline-primary cursor-pointer"
                  />
                </div>
              ))}
            </div>
            )}
          </WindowSizesContext.Provider>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default MoveImageModal
