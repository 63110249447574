import { FC, useState } from 'react'
import { Order } from '@api/gql/graphql'
import {
  useApolloClient, useMutation,
} from '@apollo/client'
import { toast } from '@components/toast'
import { Button } from '@nextui-org/button'
import { Textarea } from '@nextui-org/input'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@nextui-org/modal'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { ModalProps, UpdateCommentModalProps } from '../_types'

import { orderFragment, updateComment } from './api'

export const UpdateCommentModal: FC<ModalProps & UpdateCommentModalProps> = (props) => {
  const isMobile = useIsMobile()
  const client = useApolloClient()
  const orderCache = client.cache.readFragment<Order>({
    id: `Order:${props.order_id}`,
    fragment: orderFragment,
  })

  const [comment, setComment] = useState<string>(orderCache?.comment ?? '')

  const [updateCommentMutation, updateCommentState] = useMutation(updateComment, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const onUpdate = () => {
    updateCommentMutation({
      variables: { orderId: props.order_id, comment },
      onCompleted: () => {
        props.onClose()
      },
    })
  }

  return (
    <Modal
      placement="center"
      size="md"
      backdrop="blur"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalContent>
        {() => (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onUpdate()
            }}
            className="flex flex-col justify-center"
          >
            <ModalHeader className="flex flex-col gap-1 text-center text-xl lg:text-2xl pt-10">
              {`Update comment #${props.order_id}`}
            </ModalHeader>
            <ModalBody>
              <Textarea
                value={comment}
                placeholder="Comment"
                onValueChange={setComment}
              />

              <Button
                size={isMobile ? 'sm' : 'md'}
                type="button"
                variant="solid"
                color="primary"
                className="mb-2"
                isLoading={updateCommentState.loading}
                onClick={onUpdate}
              >
                Update
              </Button>
            </ModalBody>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}

export default UpdateCommentModal
