import {
  FC, memo, MutableRefObject, useContext, useEffect, useMemo,
} from 'react'
import { getProfile } from '@api/schemas'
import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { MAX_PHOTOS_SELECT } from '@constants/album'
import { ModalName } from '@constants/analytic'
import { faArrowsUpDownLeftRight, faTrash, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { OrderEvent, OrderEventsContext } from '@helpers/order-events'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { getLiveImages, useAlbumValidation } from '@share/album'
import { clsx } from 'clsx'

import { deleteAlbumImages, getAlbum, restoreAlbumImages } from '../api'
import { AlbumPhotosTab, getImageTag } from '../constants'

type Props = {
  albumId: string
  currentTab: AlbumPhotosTab
  selectedIds: string[]
  setSelectedIds: (ids: string[]) => void
  bodyRef: MutableRefObject<HTMLDivElement | null>
}

export const Control: FC<Props> = memo((props: Props) => {
  const isMobile = useIsMobile()
  const apolloClient = useApolloClient()
  const orderEvents = useContext(OrderEventsContext)
  const { openModal } = useModal()
  const { validateDelete } = useAlbumValidation(props.albumId)

  const [deleteAlbumImagesMutation, deleteAlbumImagesState] = useMutation(deleteAlbumImages, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const [restoreAlbumImagesMutation, restoreAlbumImagesState] = useMutation(restoreAlbumImages, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  // Скролл к выбранному изображению
  const scrollToAlbumImage = (albumImageId: string) => {
    if (!props.bodyRef.current || !albumImageId) {
      return
    }

    const albumPageElement = document.getElementById(getImageTag(albumImageId))
    if (!albumPageElement) {
      return
    }

    props.bodyRef.current.scrollTo({
      top: albumPageElement.offsetTop - props.bodyRef.current.offsetTop,
      behavior: 'smooth',
    })
  }

  const onMove = () => {
    if (!props.selectedIds.length) {
      return
    }

    if (props.selectedIds.length > MAX_PHOTOS_SELECT) {
      toast.warning(`You can move up to ${MAX_PHOTOS_SELECT} photos.`)
      return
    }

    const albumCache = apolloClient.readQuery({
      query: getAlbum,
      variables: { albumId: props.albumId },
    })

    const liveImages = getLiveImages(albumCache?.album?.pages ?? [])
    const firstSelected = liveImages.find((albumImage) => albumImage.id === props.selectedIds[0])

    openModal({
      type: ModalType.MOVE_IMAGE_MODAL,
      album_id: props.albumId,
      album_page_id: firstSelected?.album_page_id ?? undefined,
      album_image_ids: props.selectedIds,
      onCompleted: () => {
        props.setSelectedIds([])
      },
    })
  }

  const onRestore = () => {
    amplitude.event({
      type: AmplitudeEvent.AlbumClickRestorePhotos,
      album_id: props.albumId,
    })

    restoreAlbumImagesMutation({
      variables: { albumImageIds: props.selectedIds },
      onCompleted: () => {
        toast.success(`${props.selectedIds.length} photo(s) have been restored`)
      },
    })
  }

  const onDelete = () => {
    const profileCache = apolloClient.readQuery({
      query: getProfile,
    })

    amplitude.event({
      type: AmplitudeEvent.AlbumDeleteImage,
      image_ids: props.selectedIds,
      image_count: props.selectedIds.length,
      user_role: profileCache?.profile.role ?? undefined,
    })

    const albumCache = apolloClient.readQuery({
      query: getAlbum,
      variables: { albumId: props.albumId },
    })

    orderEvents.registerEvent(
      albumCache?.album?.order_id ?? '',
      OrderEvent.IMAGE_DELETED,
    )

    const validateDeleteError = validateDelete(props.selectedIds)
    if (validateDeleteError) {
      toast.warning(validateDeleteError.message)
      return
    }

    const lastSelectedId = props.selectedIds[props.selectedIds.length - 1]
    scrollToAlbumImage(lastSelectedId)

    deleteAlbumImagesMutation({
      variables: { albumImageIds: props.selectedIds },
      onCompleted: () => {
        toast.success(`${props.selectedIds.length} photo(s) have been deleted`)
        props.setSelectedIds([])
      },
    })
  }

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.AllBookPhotos,
    })
  }, [])

  const isControlEnabled = props.selectedIds.length > 0

  const animationClassName = useMemo(() => {
    if (isMobile) {
      return props.selectedIds.length ? 'bottom-0' : '-bottom-32'
    }

    return 'bottom-0'
  }, [isMobile, props.selectedIds])

  return (
    <div
      className={clsx(
        'px-6 flex gap-2 justify-center w-full absolute pb-2 pt-20 z-50 transition-all',
        'bg-gradient-to-t from-white via-white/90 to-white/0',
        animationClassName,
      )}
    >
      <Button
        variant="solid"
        isDisabled={!isControlEnabled}
        size={isMobile ? 'md' : 'lg'}
        color="primary"
        startContent={(
          <FontAwesomeIcon
            icon={faArrowsUpDownLeftRight}
            className="text-white"
          />
            )}
        onClick={onMove}
      >
        Move
      </Button>

      {props.currentTab === AlbumPhotosTab.IN_USE ? (
        <Button
          variant="solid"
          color="danger"
          isDisabled={!isControlEnabled}
          isLoading={deleteAlbumImagesState.loading}
          size={isMobile ? 'md' : 'lg'}
          startContent={
            <FontAwesomeIcon icon={faTrash} className="text-white" />
                }
          onClick={onDelete}
        >
          Delete
        </Button>
      ) : (
        <Button
          variant="solid"
          color="primary"
          isDisabled={!isControlEnabled}
          isLoading={restoreAlbumImagesState.loading}
          size={isMobile ? 'md' : 'lg'}
          startContent={
            <FontAwesomeIcon icon={faTrashArrowUp} className="text-white" />
                }
          onClick={onRestore}
        >
          Restore
        </Button>
      )}
    </div>
  )
})
