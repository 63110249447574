import {
  FC, useContext, useEffect, useMemo, useState,
} from 'react'
import { AlbumImage, AlbumPageType } from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import { defaultCoverType, parseCoverSizes } from '@constants/album'
import { ModalName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { OrderEvent, OrderEventsContext } from '@helpers/order-events'
import { Button } from '@nextui-org/button'
import {
  Modal, ModalBody, ModalContent, ModalHeader,
} from '@nextui-org/modal'
import { ModalFooter } from '@nextui-org/react'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import isEqual from 'lodash/isEqual'

import { ModalProps, SelectAlbumCoverModalProps } from '../_types'

import { getAlbum, updateAlbumCover } from './api'
import { AlbumImageItem } from './components'

export const SelectAlbumCoverModal: FC<ModalProps & SelectAlbumCoverModalProps> = (props) => {
  const isMobile = useIsMobile()
  const orderEvents = useContext(OrderEventsContext)

  const [updateAlbumCoverMutation, updateAlbumCoverState] = useMutation(updateAlbumCover, {
    onCompleted: () => {
      props.onClose()
    },
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const [selected, setSelected] = useState<string[]>([]) // Внешние id выбранных изображений

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-only',
    variables: { albumId: props.album_id },
  })

  const album = getAlbumState.data?.album
  const coverSizes = parseCoverSizes(album?.cover_type || defaultCoverType)
  const albumPages = album?.pages ?? []
  const coverImagesExternalIds = useMemo(() => {
    if (!albumPages.length) {
      return []
    }

    return albumPages[0].images?.map((image) => image.external_id)
  }, [albumPages])

  const isSelectedEqual = useMemo(
    () => isEqual(selected, coverImagesExternalIds),
    [selected, coverImagesExternalIds],
  )

  // Выбрано максимальное количество фотографий
  const isSelectedMax = coverSizes.count === selected.length

  // Осталось выбрать n фотографий
  const remainSelect = coverSizes.count - selected.length

  useEffect(() => {
    if (!albumPages.length) {
      return
    }

    // Автоматически устанавливаем фотографии обложки выбранными
    const coverPage = albumPages.find((albumPage) => albumPage.type === AlbumPageType.FrontCover)
    const coverImages = coverPage?.images ?? []
    setSelected(
      coverImages
        .map((coverImage) => coverImage.external_id as string)
        .slice(0, coverSizes.count), // Устанавливаем по размеру обложки
    )
  }, [getAlbumState.data?.album])

  // Фотографии альбома
  const albumImages = useMemo(
    () => {
      const pages = getAlbumState.data?.album?.pages ?? []
      return pages
        .filter((page) => [
          AlbumPageType.Text,
          AlbumPageType.Picture,
        ].includes(page.type as AlbumPageType))
        .reduce(
          (items, page) => [...items, ...(page.images ?? [])],
          [] as AlbumImage[],
        )
    },
    [getAlbumState.data?.album],
  )

  const selectedAlbumImages = useMemo(
    () => {
      let items: AlbumImage[] = []

      selected.forEach((externalId) => {
        const item = albumImages.find((albumImage) => albumImage.external_id === externalId)
        if (item) {
          items = [...items, item]
        }
      })

      return items
    },
    [albumImages, selected],
  )

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.SelectAlbumCover,
    })
  }, [])

  const onToggleSelected = (externalId?: string | null) => {
    if (!externalId) {
      return
    }

    // Удаление фотографии из выбранных
    if (selected.includes(externalId)) {
      setSelected(selected.filter((id) => id !== externalId))
      return
    }

    // Проверяем чтобы пользователь не выбрал больше фотографий чем на обложке
    const { count } = parseCoverSizes(getAlbumState.data?.album?.cover_type || defaultCoverType)
    if (selected.length >= count) {
      toast.info('To add a new photo, deselect one from the top selection')
      return
    }

    setSelected([...selected, externalId])
  }

  const onSave = () => {
    if (!getAlbumState.data?.album?.order_id) {
      return
    }

    amplitude.event({
      type: AmplitudeEvent.AlbumUpdateCover,
      album_id: props.album_id,
    })

    orderEvents.registerEvent(
      getAlbumState.data.album.order_id,
      OrderEvent.COVER_EDITED,
    )

    updateAlbumCoverMutation({
      variables: {
        input: {
          album_id: props.album_id,
          external_ids: selected,
        },
      },
    })
  }

  return (
    <Modal
      placement={isMobile ? 'bottom-center' : 'top'}
      size={isMobile ? 'full' : '5xl'}
      backdrop="blur"
      scrollBehavior="inside"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className="flex flex-col gap-1 font-medium text-center text-xl lg:text-2xl pt-10">
              Choose photos for the cover
            </ModalHeader>
            <ModalBody className="gap-0">
              <div className="grid grid-cols-5 md:grid-cols-10 gap-1 border-b border-b-gray-200 mb-4 pb-4">
                {selectedAlbumImages.map((albumImage) => (
                  <AlbumImageItem
                    key={albumImage.id}
                    albumImage={albumImage}
                    selectedIndex={selected.findIndex(
                      (externalId) => albumImage.external_id === externalId,
                    )}
                    onClick={() => onToggleSelected(albumImage.external_id)}
                  />
                ))}
              </div>

              <div className="grid grid-cols-3 md:grid-cols-6 gap-1">
                {albumImages.map((albumImage) => (
                  <AlbumImageItem
                    key={albumImage.id}
                    albumImage={albumImage}
                    selectedIndex={selected.findIndex(
                      (externalId) => albumImage.external_id === externalId,
                    )}
                    onClick={() => onToggleSelected(albumImage.external_id)}
                  />
                ))}
              </div>
            </ModalBody>
            <ModalFooter className="grid grid-cols-3">
              <div>
                <Button
                  variant="bordered"
                  isDisabled={selected.length === 0}
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="flex justify-center">
                <Button
                  color="primary"
                  isDisabled={isSelectedEqual || !isSelectedMax}
                  isLoading={updateAlbumCoverState.loading}
                  onClick={onSave}
                >
                  <span className="md:hidden">Apply</span>
                  <span className="hidden md:inline-block">Apply changes</span>
                </Button>
              </div>

              <div className="flex items-center justify-end text-tiny md:text-sm text-gray-800 text-center">
                {!!remainSelect && `${remainSelect} photos left to choose`}
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default SelectAlbumCoverModal
