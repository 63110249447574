import { graphql } from '@api/gql/gql'

export const getTask = graphql(`
    query getTask($key: String!) {
        task(key: $key) {
            status
            steps
        }
    }
`)
