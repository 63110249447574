import { graphql } from '@api/gql/gql'

export const getAlbum = graphql(`
    query getAlbumFromUpdateAlbumLayoutModal($albumId: ID!) {
        album(id: $albumId) {
            id
            cover_filter
            cover_color
            cover_type
            updated_at
        }
    }
`)
