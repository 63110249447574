import { graphql } from '@api/gql/gql'

export const updateComment = graphql(`
    mutation updateComment($orderId: ID!, $comment: String!) {
        update_order(input: { id: $orderId, comment: $comment }) {
            id
            comment
        }
    }
`)
