import cookie from 'js-cookie'

const getDefaultOptions = (): Cookies.CookieAttributes => ({
  path: '/',
  secure: !import.meta.env.DEV,
  domain: import.meta.env.VITE_LANDING_PAGE_URL.replace('http://', ''),
})

/**
 * У Facebook срок жизни токена 60 суток.
 * Здесь cookie истечет на день раньше чтобы пользователь не увидел ошибку доступа к api
 * Токен тоже наследует срок жизни от facebook access token
 */
export const tokenStore = {
  set: (token: string, expires?: Date) => {
    cookie.set('token', token, {
      ...getDefaultOptions(),
      expires,
    })
  },
  get: () => cookie.get('token'),
  remove: () => cookie.remove('token', getDefaultOptions()),
}
