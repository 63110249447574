import { TaskStatus } from '@api/gql/graphql'
import { dropTask, subscriptionOnTask } from '@api/schemas'
import { useMutation, useSubscription } from '@apollo/client'
import { toast } from '@components/toast'
import { taskKey } from '@constants/task'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { ModalType } from '@modals/_types'
import { AlbumPhotosTab } from '@modals/album-photos-modal/constants'
import { useModal } from '@modals/core/use-modal'

type Props = {
  albumId?: string | null;
  onCompleted: () => void;
};

export const useDuplicateResultsStatus = (props: Props) => {
  const { openModal } = useModal()

  const [dropTaskMutation] = useMutation(dropTask, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  useSubscription(subscriptionOnTask, {
    variables: { key: taskKey.duplicateResults(props.albumId) },
    onData: async (data) => {
      const task = data.data.data?.subscribe_on_task
      if (!task) {
        return
      }

      const message = task.steps[task.steps.length - 1]

      if (task.status === TaskStatus.Completed && message) {
        await dropTaskMutation({
          variables: { key: taskKey.createAlbums() },
        })

        toast.infoButton(
          message,
          {
            buttonTitle: 'Review',
            onClick: async () => {
              amplitude.event({
                type: AmplitudeEvent.AlbumClickDeletedReview,
                album_id: props.albumId as string,
              })

              openModal({
                type: ModalType.ALBUM_PHOTOS_MODAL,
                album_id: props.albumId as string,
                tab: AlbumPhotosTab.DELETED,
              })
            },
            onDismiss: () => {
              dropTaskMutation({
                variables: {
                  key: taskKey.duplicateResults(props.albumId),
                },
              })
            },
            duration: 10 * 1000,
          },
        )

        props.onCompleted()
      }
    },
  })
}
