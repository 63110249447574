import { FC, memo } from 'react'
import { CoverFilter } from '@api/gql/graphql'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clsx } from 'clsx'

type Props = {
  coverFilter: CoverFilter;
  isActive?: boolean
  onClick?: () => void
};

export const CoverFilterSquare: FC<Props> = memo((props: Props) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center w-10 h-10 border rounded-lg outline outline-2 hover:outline-primary transition-all cursor-pointer',
        props.coverFilter === CoverFilter.None ? 'border-gray-200' : 'border-transparent',
        props.coverFilter === CoverFilter.Pink && 'bg-filter-pink',
        props.coverFilter === CoverFilter.Gray && 'bg-filter-gray',
        props.coverFilter === CoverFilter.Violet && 'bg-filter-violet',
        props.isActive ? 'outline-primary' : 'outline-transparent',
      )}
      onClick={props.onClick}
    >
      {props.coverFilter === CoverFilter.None && (
        <FontAwesomeIcon
          icon={faClose}
          className="text-gray-400"
        />
      )}
    </div>
  )
})
