import {
  FC, useContext, useEffect, useRef,
} from 'react'
import { Album } from '@api/gql/graphql'
import { OrderEvent, OrderEventsContext } from '@helpers/order-events'

type Props = {
  album?: Album | null;
};

export const EndObserver: FC<Props> = (props) => {
  const targetRef = useRef<HTMLDivElement | null>(null)
  const orderEvents = useContext(OrderEventsContext)

  // Когда пользователь долистает всю книгу мы испускаем событие
  // Которая закроет все подсказки
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (!props.album?.order_id) {
          return
        }

        orderEvents.registerEvent(props.album.order_id, OrderEvent.SCROLLED_END)
      }
    }, { threshold: 1.0 })

    if (targetRef.current) {
      observer.observe(targetRef.current)
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [])

  return <div ref={targetRef} className="w-full h-1" />
}
