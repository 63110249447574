import { FC } from 'react'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'

type Props = {
  onClose: () => void
  isPrevDisabled: boolean
  isNextDisabled: boolean
  onPrev: () => void
  onNext: () => void
}

export const FooterNavigation: FC<Props> = (props) => {
  return (
    <div className="fixed bottom-0 w-full flex justify-center pb-8 px-4">
      <div className="grid grid-cols-12">
        <div className="col-span-3">
          <Button
            variant="light"
            isIconOnly
            className="w-full"
            isDisabled={props.isPrevDisabled}
            startContent={<FontAwesomeIcon icon={faChevronLeft} size="lg" className="text-gray-400" />}
            onClick={props.onPrev}
          />
        </div>

        <div className="col-span-6">
          <Button
            variant="solid"
            color="primary"
            size="lg"
            className="rounded-full w-full"
            onClick={props.onClose}
          >
            Return to the book
          </Button>
        </div>

        <div className="col-span-3">
          <Button
            variant="light"
            isIconOnly
            className="w-full"
            isDisabled={props.isNextDisabled}
            startContent={<FontAwesomeIcon icon={faChevronRight} size="lg" className="text-gray-400" />}
            onClick={props.onNext}
          />
        </div>
      </div>
    </div>
  )
}
