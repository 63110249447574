import { Outlet } from 'react-router-dom'
import { Container } from '@components/container'

import { FixedButtons, Footer, Header } from './components'

export const MainLayout = () => {
  return (
    <div style={{ minHeight: '100vh' }} className="flex flex-col justify-between">
      <Container className="pt-5">
        <Header />
        <Outlet />
      </Container>
      <div className="py-10">
        <Container>
          <Footer />
        </Container>
      </div>

      <FixedButtons />
    </div>
  )
}
