import {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { useQuery } from '@apollo/client'
import { ModalName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { Modal, ModalContent, ModalHeader } from '@nextui-org/modal'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { Key } from '@react-types/shared'
import { usePages, useRecentPhotos } from '@share/album'

import { AlbumPhotosModalProps, ModalProps } from '../_types'

import { getAlbum } from './api'
import {
  AlbumImageItem, Control, NavigationTabs, RecentPhotos,
} from './components'
import { AlbumPhotosTab, getImageTag } from './constants'

export const AlbumPhotosModal: FC<ModalProps & AlbumPhotosModalProps> = (
  props,
) => {
  const isMobile = useIsMobile()
  const { addRecentPhotos, isLoadingRecentPhotos } = useRecentPhotos()

  const bodyRef = useRef<HTMLDivElement | null>(null)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [currentTab, setCurrentTab] = useState<AlbumPhotosTab>(props.tab ?? AlbumPhotosTab.IN_USE)

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-only',
    variables: { albumId: props.album_id },
  })

  const album = getAlbumState.data?.album

  const pages = album?.pages ?? []
  const { imagesLive, imagesDeleted } = usePages(pages)

  const onChangeTab = useCallback((key: Key) => {
    const tab = key.toString() as AlbumPhotosTab

    amplitude.event({
      type: AmplitudeEvent.AlbumClickPhotosTab,
      album_id: props.album_id,
      tab,
    })

    setCurrentTab(tab)
  }, [])

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.AllBookPhotos,
    })
  }, [])

  const tabImages = useMemo(
    () => (currentTab === AlbumPhotosTab.IN_USE ? imagesLive : imagesDeleted),
    [currentTab, imagesLive, imagesDeleted],
  )

  useEffect(() => {
    setSelectedIds([])
  }, [currentTab])

  const memoizedSelectedIds = useMemo(() => selectedIds, [selectedIds])

  const toggleSelectedIds = useCallback((albumImageId: string) => {
    setSelectedIds((s) => {
      if (s.includes(albumImageId)) {
        return s.filter((id) => id !== albumImageId)
      }

      return [...s, albumImageId]
    })
  }, [])

  const toggleHandlers = useMemo(() => {
    const handlers: Record<string, () => void> = {}
    tabImages.forEach((image) => {
      handlers[image.id] = () => toggleSelectedIds(image.id)
    })
    return handlers
  }, [tabImages, toggleSelectedIds])

  return (
    <Modal
      placement={isMobile ? 'bottom-center' : 'top'}
      size={isMobile ? 'full' : '5xl'}
      backdrop="blur"
      scrollBehavior="inside"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalContent className="overflow-hidden">
        <>
          <ModalHeader className="flex flex-col gap-1 pb-2 md:pb-4 font-medium text-center text-xl lg:text-2xl pt-10">
            All book photos
          </ModalHeader>

          <NavigationTabs
            currentTab={currentTab}
            onChangeTab={onChangeTab}
          />

          <div
            ref={bodyRef}
            className="flex flex-1 flex-col px-3 md:px-6 md:py-2 overflow-y-auto gap-0 relative pb-32 md:min-h-80"
          >
            <div className="grid grid-cols-3 md:grid-cols-8 gap-1 md:mb-32">
              {tabImages.map((albumImage) => (
                <AlbumImageItem
                  id={getImageTag(albumImage.id)}
                  key={albumImage.id}
                  isPageNumber={currentTab === AlbumPhotosTab.IN_USE}
                  isShowDuplicate={currentTab === AlbumPhotosTab.DELETED}
                  albumImage={albumImage}
                  isActive={memoizedSelectedIds.includes(albumImage.id)}
                  onClick={toggleHandlers[albumImage.id]}
                />
              ))}

              <RecentPhotos
                isLoading={isLoadingRecentPhotos}
                onClick={() => {
                  if (!album?.order_id) {
                    return
                  }

                  addRecentPhotos(album)
                }}
              />
            </div>

            {!tabImages.length && (
              <div className="text-gray-400 mt-4 text-center">
                {currentTab === AlbumPhotosTab.IN_USE
                  ? 'Images not found'
                  : 'Deleted images not found'}
              </div>
            )}
          </div>

          <Control
            albumId={props.album_id}
            currentTab={currentTab}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            bodyRef={bodyRef}
          />
        </>
      </ModalContent>
    </Modal>
  )
}

export default AlbumPhotosModal
