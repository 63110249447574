import {
  FC, useEffect, useMemo, useState,
} from 'react'
import { AlbumPageType } from '@api/gql/graphql'
import { useQuery } from '@apollo/client'
import { ModalName } from '@constants/analytic'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { ModalLayout } from '@layouts/modal-layout'
import {
  Modal,
  ModalContent,
} from '@nextui-org/modal'
import {
  PageItem,
  PageNavigation,
  PictureControlContext,
  usePages,
  useWindowSizes,
  WindowSizesContext,
} from '@share/album'
import { PictureControls } from '@share/album/types'

import { AlbumPageModalProps, ModalProps } from '../_types'

import { getAlbum } from './api'
import { FooterNavigation } from './components'

export const AlbumPageModal: FC<ModalProps & AlbumPageModalProps> = (props) => {
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(-100)

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-only',
    variables: { albumId: props.album_id },
  })

  const album = getAlbumState.data?.album ?? null
  const pages = album?.pages ?? []
  const modalTitle = `${album?.title} ${album?.subtitle}`

  const { pagesLive } = usePages(pages)
  const windowSizes = useWindowSizes()

  useEffect(() => {
    const currentPage = pagesLive.find((page) => page.id === props.album_page_id)
    if (currentPage) {
      setCurrentPageNumber(currentPage.page_number)
    }
  }, [props.album_page_id])

  const currentPage = useMemo(
    () => pagesLive.find((page) => page.page_number === currentPageNumber) ?? null,
    [pagesLive, currentPageNumber],
  )

  const currentPageIndex = useMemo(
    () => pagesLive.findIndex((page) => page.page_number === currentPageNumber),
    [pagesLive, currentPageNumber],
  )

  const prevPage = useMemo(
    () => pagesLive[currentPageIndex - 1] ?? null,
    [pagesLive, currentPageIndex],
  )

  const nextPage = useMemo(
    () => pagesLive[currentPageIndex + 1] ?? null,
    [pagesLive, currentPageIndex],
  )

  const isPrevPageDisabled = useMemo(
    () => !(prevPage?.type && [AlbumPageType.Picture, AlbumPageType.Text]
      .includes(prevPage.type as AlbumPageType)),
    [prevPage],
  )

  const isNextPageDisabled = useMemo(
    () => !(nextPage?.type && [AlbumPageType.Picture, AlbumPageType.Text]
      .includes(nextPage.type as AlbumPageType)),
    [nextPage],
  )

  const onPrev = () => {
    if (currentPageIndex > 0 && prevPage) {
      setCurrentPageNumber(prevPage.page_number)
    }
  }

  const onNext = () => {
    if (currentPageIndex > 0 && nextPage) {
      setCurrentPageNumber(nextPage.page_number)
    }
  }

  // Не показываем заднюю обложку при удалении последней страницы
  useEffect(() => {
    if (![AlbumPageType.Picture, AlbumPageType.Text].includes(currentPage?.type as AlbumPageType)) {
      onPrev()
    }
  }, [currentPage?.id])

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.AlbumPage,
    })
  }, [])

  const pictureControl = useMemo((): PictureControls => ({
    delete: true,
    move: true,
  }), [])

  const onClose = () => {
    if (currentPage) {
      // Переход к просматриваемой странице на альбоме
      props.onAlbumPage(currentPage?.id)
    }

    props.onClose()
  }

  return (
    <Modal
      size="full"
      backdrop="blur"
      placement="center"
      scrollBehavior="inside"
      hideCloseButton
      isOpen={props.isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <ModalLayout title={modalTitle} onBack={onClose}>
          <div className="page">
            <PageNavigation
              title={currentPage ? `Page ${currentPage?.page_number}` : ''}
              isPrevDisabled={isPrevPageDisabled}
              isNextDisabled={isNextPageDisabled}
              onPrev={onPrev}
              onNext={onNext}
              className="mb-10"
            />

            <div ref={windowSizes.ref}>
              <WindowSizesContext.Provider value={windowSizes}>
                <PictureControlContext.Provider value={pictureControl}>
                  {album && currentPage && (
                    <PageItem
                      key={currentPage.id}
                      album={album}
                      page={currentPage}
                      spreadIndex={0}
                      isLeft
                      isWide
                      isControl
                      isSpread
                    />
                  )}
                </PictureControlContext.Provider>
              </WindowSizesContext.Provider>

              {!currentPage && (
                <div className="flex flex-col items-center text-gray-600 text-center">
                  <FontAwesomeIcon icon={faTrashCan} size="xl" className="text-gray-300 mb-4" />
                  <div className="mb-2">The page has been deleted</div>
                </div>
              )}
            </div>

            <FooterNavigation
              onClose={onClose}
              isPrevDisabled={isPrevPageDisabled}
              isNextDisabled={isNextPageDisabled}
              onPrev={onPrev}
              onNext={onNext}
            />
          </div>
        </ModalLayout>
      </ModalContent>
    </Modal>
  )
}

export default AlbumPageModal
