import { FC } from 'react'
import { AlbumImage } from '@api/gql/graphql'
import { clsx } from 'clsx'

type Props = {
  albumImage: AlbumImage
  selectedIndex: number
  onClick: () => void
}

export const AlbumImageItem: FC<Props> = (props) => {
  const isActive = props.selectedIndex !== -1
  return (
    <div
      className="w-full aspect-square relative flex justify-center cursor-pointer rounded-2xl"
      onClick={props.onClick}
    >
      <img
        className={clsx(
          'object-cover w-full h-full rounded-2xl border-2 border-white hover:border-primary transition-colors',
          isActive && 'border-2 !border-primary',
        )}
        src={props.albumImage.image_medium_url ?? ''}
        alt=""
      />

      {isActive && <div className="absolute w-full h-full bg-primary/20 rounded-2xl" />}

      {isActive && (
        <div
          className={clsx(
            'absolute bottom-2 z-10 bg-primary rounded-full flex items-center justify-center',
            'w-5 h-5 md:w-8 md:h-8 text-white font-bold text-xs lg:text-base',
          )}
        >
          {(props.selectedIndex || 0) + 1}
        </div>
      )}
    </div>
  )
}
