import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { COOKIE_POLICY, PRIVACY_LINK } from '@constants/link'
import { clsx } from 'clsx'

const now = new Date()
const landingPageUrl = import.meta.env.VITE_LANDING_PAGE_URL
const termsAndConditionsUrl = `${landingPageUrl}/terms-and-conditions`

export const Footer = () => {
  const location = useLocation()

  // Дополнительный отступ. Без него кнопка заказа перекрывает футер
  const isAddBottom = useMemo(() => location.pathname.match('/orders/'), [location.pathname])

  return (
    <div className={clsx(
      'flex flex-wrap md:flex-nowrap gap-2.5 justify-between text-base font-regular page pt-10',
      isAddBottom ? 'mb-20' : '',
    )}
    >
      <div className="text-xs md:text-base text-zinc-400 md:text-black">
        Easy Photo Book ©
        {' '}
        {now.getFullYear()}
      </div>
      <div className="text-xs md:text-base text-zinc-400 md:text-black">
        <a href="mailto:info@easyphotobook.me" className="hover:underline" rel="noreferrer">
          info@easyphotobook.me
        </a>
      </div>
      <div className="flex gap-10 text-xs md:text-base w-full md:w-auto justify-between md:justify-end">
        <a className="hover:underline" href={PRIVACY_LINK}>Privacy policy</a>
        <a className="hover:underline" href={COOKIE_POLICY}>Cookies policy</a>
        <a className="hover:underline" href={termsAndConditionsUrl}>
          Terms & conditions
        </a>
      </div>
    </div>
  )
}
