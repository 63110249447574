import { Route, Routes } from 'react-router-dom'
import { Role } from '@api/gql/graphql'
import {
  AdminLayout,
  MainLayout,
  NotFoundLayout,
  PaymentLayout,
  PrintLayout,
} from '@layouts/index'
import {
  AdminOrdersPage,
  CreatePage,
  CurrentPage,
  DeprecatedCreatePage,
  DeprecatedOrderPaymentLink,
  NotFoundPage,
  OrderPage,
  OrdersPage,
  PaymentFailedPage,
  PaymentSuccessPage,
  PrintBookPage,
  PrintSpinePage,
  ProfilePage,
} from '@pages/index'
import { ProtectedRoute } from '@share/protected-route'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PaymentLayout />}>
        <Route
          path="/payment-failed"
          element={<PaymentFailedPage />}
        />
        <Route
          path="/payment-success"
          element={<PaymentSuccessPage />}
        />
      </Route>
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/"
          element={<CreatePage />}
        />
        <Route
          path="/preview/highlights/:from_unix/:to_unix"
          element={<DeprecatedCreatePage />}
        />
        <Route
          path="/orders"
          element={<OrdersPage />}
        />
        <Route
          path="/orders/current"
          element={<CurrentPage />}
        />
        <Route
          path="/orders/:orderId"
          element={<OrderPage />}
        />
        <Route
          path="/profile"
          element={<ProfilePage />}
        />
      </Route>
      <Route
        path="/admin"
        element={(
          <ProtectedRoute roles={[Role.Admin, Role.Moderator]}>
            <AdminLayout />
          </ProtectedRoute>
        )}
      >
        <Route
          path="/admin"
          element={<AdminOrdersPage />}
        />
      </Route>
      <Route path="/print" element={<PrintLayout />}>
        <Route
          path="/print/book/:albumId"
          element={<PrintBookPage />}
        />
        <Route
          path="/print/spine/:albumId"
          element={<PrintSpinePage />}
        />
      </Route>

      {/* Поддержка старого способа открытия оплаты заказа */}
      <Route path="/api/orders/order-checkout/:orderId" element={<DeprecatedOrderPaymentLink />} />

      <Route element={<NotFoundLayout />}>
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
    </Routes>
  )
}
